import type { Locale } from '~/types/locale'

/**
 * We don't always translate our content to every language so when using locale key to get an image use this to make sure you target actual uploaded content!
 */
export function getMediaLocaleFromLocale(currentLocale: Locale) {
  switch (currentLocale) {
    case 'en':
      return 'en'
    case 'fr':
      return 'fr'
    default:
      return 'en'
  }
}
